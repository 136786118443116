import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li><Link onClick={props.onToggleMenu} to="/">Home</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/intro">Introduction</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/comp_a">Competency A</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/comp_b">Competency B</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/comp_c">Competency C</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/comp_d">Competency D</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/comp_e">Competency E</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/comp_f">Competency F</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/conclusion">Conclusion and Affirmation</Link></li>
            </ul>
        </div>
        <a className="close" onClick={props.onToggleMenu} href="javascript:;">Close</a>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
